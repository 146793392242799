<template>
    <el-popover
        popper-class="dapartment_tree_selector"
        placement="bottom-start"
        trigger="manual"
        :disabled="inputDisabled"
        :visible-arrow="false"
        :width="width"
        v-clickoutside="handleClose"
        v-model.trim="visible">
        <el-input :disabled="inputDisabled" ref="input" :placeholder="placeholder" slot="reference" :value="departName" @focus="handleFocus"  clearable @clear="handleClear"> </el-input>
        <div ref="categoryPopover">
          <el-input :placeholder="placeholder" :size="size" v-model.trim="keyword"></el-input>
          <el-tree :data="targetLists"
              node-key="id"
              :size="size"
              ref="categoryTree"
              :current-node-key='currentNodeKey'
              :default-checked-keys="defaultCheckedKeys"
              :filter-node-method="filterNode"
              :props="{
                  label:(data)=>(data.name),
                  children:'children',
              }"
              @node-click="handleNodeClick">
          </el-tree>
        </div>
    </el-popover>
</template>
<script>
import Clickoutside from 'element-ui/src/utils/clickoutside';
import SystemSetting from '@/api/systemSetting';
export default {
  name: 'DepartMentSelector',
  directives: { Clickoutside },
  props: {
    value: {
      type: String,
      default () {
        return '';
      },
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    isAllowLeaf: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default () {
        return '请选择上级部门';
      },
    },
  },
  inject: {
    elForm: {
      default: '',
    },
  },
  computed: {
    inputDisabled () {
      return this.disabled || (this.elForm || {}).disabled;
    },
  },
  data () {
    return {
      visible: false,
      targetLists: [],
      categoryCacheMap: {},
      width: 100,
      departName: this.name,
      defaultCheckedKeys: [this.value],
      keyword: '',
      currentNodeKey: '',
    };
  },
  watch: {
    keyword (val) {
      this.$refs.categoryTree.filter(val);
    },
    async value () {
      let departList = this.targetLists;

      if (!departList || !departList.length) {
        departList = await SystemSetting.getDepartmentLists({});
      }

      this.getChianName(this.value);
    },
    name (val = '') {
      this.departName = val;
    },
  },
  methods: {
    filterNode (value, data) {
      if (!value) return true;
      let label = data.name;
      return label.indexOf(value) !== -1;
    },
    handleFocus () {
      if (this.readonly) return;
      this.visible = !this.visible;
      this.getTargetLists();
    },
    handleNodeClick (data) {
      if (data.level === 3 && !this.isAllowLeaf) {
        this.$JZShowAlert('部门最多支持三级管理，请重新选择上级部门！', 'error');
        return;
      }
      this.getChianName(data.id);
      this.$emit('input', data.id);
      this.$emit('update:name', data.name);
      this.$emit('change', data);
      this.departName = data.name;
      this.visible = false;
    },
    getChianName (nodeId) {
      let selectedDepartmentList = [];
      while (nodeId) {
        let node = this.categoryCacheMap[nodeId];
        if (node) {
          selectedDepartmentList.unshift(node);
          nodeId = node.pid;
        } else {
          nodeId = '';
        }
      }
      let currentInfo = selectedDepartmentList[selectedDepartmentList.length - 1];
      this.departName = currentInfo?.name || this.departName;

      this.$emit('update:name', this.departName);
    },
    handleClose () {
      this.visible = false;
      this.keyword = '';
    },
    getTargetLists () {
      SystemSetting.getDepartmentLists({}).then(this.getTargetListsSuccessFun);
    },
    getTargetListsSuccessFun (jsonData) {
      let currentLists = [];
      if (jsonData.heads.code === 200) {
        currentLists = jsonData.body || [];
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
      this.cacheNode(currentLists);
      this.targetLists = currentLists || [];
      if (this.value) {
        this.currentNodeKey = this.value;
        this.getChianName(this.value);
      } else {
        this.handleClear();
      }
    },
    handleClear () {
      this.$emit('input', '');
      this.$emit('update:name', '');
      this.$emit('change', {});
      this.departName = '';
    },
    calcPopoverWidth () {
      let rect = this.$refs.input.$el.getBoundingClientRect();
      this.width = rect.width;
    },
    cacheNode (sources) {
      sources.forEach(c => {
        this.categoryCacheMap[c.id] = c;
        if (c.children) {
          this.cacheNode(c.children);
        }
      });
    },
  },
  mounted () {
    this.calcPopoverWidth();
    this.popperElm = this.$refs.categoryPopover;
    window.addEventListener('resize', this.calcPopoverWidth);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcPopoverWidth);
  },
};
</script>
<style lang="scss">
.dapartment_tree_selector{
  max-height: 300px;
  overflow: auto;

  .el-tree {
    padding-top: 8px;
  }
}
</style>
