import Enum from '@/utils/Enum';

const OrderStatusEnumList = [
  {
    value: 'TO_BE_ASSIGNED',
    name: '待指派',
  },
  {
    value: 'ASSIGNED',
    name: '已指派',
  },
  {
    value: 'CANCELLED',
    name: '已取消',
  },
  {
    value: 'ORDER_RECEIVED',
    name: '已接单',
  },
  {
    value: 'IN_MAINTENANCE',
    name: '维修中',
  },
  {
    value: 'SIGNED',
    name: '已签字',
  },
  {
    value: 'COMPLETED',
    name: '已完成',
  },
  {
    value: 'BE_EVALUATED',
    name: '待评价',
  },
  {
    value: 'EVALUATED',
    name: '已评价',
  },
];

export default new Enum(OrderStatusEnumList);
export { OrderStatusEnumList };
