import server from '@/request';
// 查询工单时效报表
export const getOrderTimeConsumingReport = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/report/work/order/time', params);
};

// 查询工单数量报表
export const getOrderCountReport = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/report/work/order/quantity', params);
};

// 查询工程师工单列表
export const getEngineerOrderList = (params)=> {
  return server.post('/xiaozi-xmb/wireless/saas/report/employee/work/orders', params);
};
