/**
 * 创建Enum对象
 * @param {Array<String> | Array<{name: String, value: Number}} items 要创建的枚举值列表
 * @returns 返回创建的枚举对象
 */
export default class Enum {
  /**
     * Creates an instance of Enum.
     *
     * @param {Array<String | {name: String, value: Number}>} items 枚举项
     *
     * @memberOf Enum
     */
  constructor (items) {
    if (!Array.isArray(items) || items.length === 0) {
      return;
    }

    items.forEach((item) => {
      let {value, name} = item;
      this[value] = name;
      this[name] = value;
    });
  }
}

export function wrapWithAll (list, name = '全部', desc = '全部') {
  let result = [];
  result.push({value: null, name, desc});
  list.forEach(t => {
    result.push(t);
  });
  return result;
}
